import React from 'react'
import CookieConsent from 'react-cookie-consent'
import useWindowSize from 'styles/window-size'

import { Wrapper, Content, LinkItem } from './index.styled'

const CookiesBanner = () => {
  const ref = React.useRef()
  const { width } = useWindowSize()

  React.useEffect(() => {
    setTimeout(() => {
      ref?.current?.classList?.add('show')
    }, 600)
  }, [])

  return (
    <Wrapper ref={ref}>
      <CookieConsent
        location='none'
        buttonText='Accept All'
        disableStyles
        containerClasses='cookies-banner'
        buttonClasses='cookies-banner-accept-btn'
        disableButtonStyles
        buttonWrapperClasses='cookies-banner-btns'
        contentClasses='cookies-banner-content'
        expires={150}
        // debug={true}
      >
        <Content>
          {width >= 768
            ? (
              <>
                We use cookies to improve your experience. By using this website you agree to our <LinkItem to='/legal'>Cookie Policy</LinkItem>.
              </>
              )
            : (
              <>
                This website uses <LinkItem to='/legal'>cookies</LinkItem>.
              </>
              )}
        </Content>
      </CookieConsent>
    </Wrapper>
  )
}
export default CookiesBanner
