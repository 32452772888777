import React from 'react'
import styled from 'styled-components'
import {
  mediaMax,
  calculateResponsiveSize,
  calculateMobileResponsiveSize
} from 'styles/media-queries'
import { Link } from 'gatsby'

export const Wrapper = styled.div`
  .cookies-banner {
    position: fixed;
    bottom: ${calculateResponsiveSize(20)};
    left: ${calculateResponsiveSize(142)};
    right: ${calculateResponsiveSize(142)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${calculateResponsiveSize(12)} ${calculateResponsiveSize(20)};
    font-size: ${calculateResponsiveSize(14)};
    color: #52403A;
    line-height: 1;
    background: #F9F1EC;
    opacity: 0;
    visibility: hidden;
    transform: translateY(150%);
    transition: opacity 1s, transform 1s;
    z-index: 1000;
  }
  &.show {
    .cookies-banner {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
  .cookies-banner-accept-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${calculateResponsiveSize(24)};
    padding: 0 ${calculateResponsiveSize(12)};
    font-size: ${calculateResponsiveSize(12)};
    color: #F9F1EC;
    line-height: 1;
    font-weight: 600;
    letter-spacing: ${calculateResponsiveSize(3.84)};
    text-transform: uppercase;
    border: 0;
    background: #52403A;
    transition: color 0.25s, background 0.25s;
    cursor: pointer;
    /* &:hover {
      color: #000000;
      background: #ffffff;
    } */
  }
  ${mediaMax('mobile')} {
    .cookies-banner {
      top: 0;
      bottom: auto;
      left: 0;
      right: 0;
      padding: ${calculateMobileResponsiveSize(12)} ${calculateMobileResponsiveSize(20)};
      font-size: ${calculateMobileResponsiveSize(14)};
      transform: translateY(-150%);
    }
    .cookies-banner-accept-btn {
      height: ${calculateMobileResponsiveSize(22)};
      padding: 0 ${calculateMobileResponsiveSize(12)};
      font-size: ${calculateMobileResponsiveSize(10)};
      letter-spacing: ${calculateMobileResponsiveSize(2.2)};
    }
  }
`

export const Content = styled.div``

export const LinkItem = styled((props) => <Link {...props} />)`
  text-decoration: none;
  color: #52403A;
  &:hover {
    text-decoration: underline;
  }
`
